<template>
   <div id="inicio" class="user-start-no">
      <div>
         <div class="vx-row">
            <!-- OVERLAY CARD -->
            <div class="vx-col w-full mb-3">
               <vx-card class="big-title-card-bg h-auto">
                  <h1 class="rg-big-title extrabold">
                     ¡Bienvenid@ a la <span>Red</span>!
                  </h1>
               </vx-card>
            </div>
         </div>

         <!-- PANEL DE CUMPLIMIENTO -->
         <compliance-banner @loadedRequirements="loadedRequirements"/>

         <!-- BANNER DE PLANES DE INVERSION (ESPERANDO LIBERACIÓN) -->
         <!-- <div class="vx-row">
            <div class="vx-col md:w-1/2 w-full">
                  <investment-plans-banner></investment-plans-banner>
               </div>
         </div> -->

         <vx-card v-if="showInvestmentCard" class="mb-base mt-5" card-background="#d0eadc">
            <div class="vx-row">
               <div class="vx-col w-full">
                  <div slot="header" class="flex flex-wrap items-center flex-grow justify-between">
                     <img :src="figs.arrows" style="width: 50px" alt="" class="mr-5">
                     <div class="vx-row mt-3" style="display: unset;">
                        <div class="vx-col">
                           <h2 class="bold">¡Abona fondos a tu cuenta!</h2>
                        </div>
                        <div class="vx-col">
                           <label>Ya puedes abonar a tu cuenta de forma fácil y segura. Da clic en Abonar y te decimos como.</label>
                        </div>
                     </div>
                     <vs-button class="ml-auto" color="dark" @click.stop="goToFunds">Abonar</vs-button>
                  </div>
               </div>
            </div>
         </vx-card>
         <div class="mt-5" v-else-if="mainComplianceRequirements <= 0">
            <template v-if="isMounted">
               <investor-contract-level-banner :show-balance="false"/>
            </template>
         </div>

         <div class="vx-row mt-base">
            <div class="vx-col w-full">
               <div class="flex items-end px-3">
                  <h2>Resumen de tus inversiones</h2>
               </div>
            </div>
         </div>

         <div class="vx-row">
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.portfolio" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(totalPortfolio) }} MXN
                        </h3>
                        <label>
                           Valor del portafolio
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToPortfolio">Ir al portafolio</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.invest" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(availableToInvest) }} MXN
                        </h3>
                        <label>
                           Disponible para inversión
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestments">Invertir</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.commitment" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(investmentCommitments) }} MXN
                        </h3>
                        <label>
                           Compromisos de inversión
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestorProjects">Ir a proyectos</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>
            <div class="vx-col lg:w-1/4 md:w-1/2 w-full">
               <vx-card class="h-auto text-center mt-5">
                  <div class="vx-row">
                     <div class="vx-col w-full">
                        <img :src="figs.fixed" style="width: 20%; display: unset;" alt="">
                        <h3 class="mt-5 mb-3">
                           {{ explicitMoneyFormat(activeFixedInvestment) }} MXN
                        </h3>
                        <label>
                           Inversión fija
                        </label>
                     </div>
                  </div>
                  <div class="vx-row mt-5">
                     <div class="vx-col w-full">
                        <vs-button class="mb-2" @click.stop="goToInvestorProjects">Ver más</vs-button>
                     </div>
                  </div>
               </vx-card>
            </div>

<!-- INICIAN COMPONENTES VERSION ANTERIOR DE HOME -->
            <!-- COL 1 -->
            <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <notice-card
            v-if="showAutomaticInvestmentNoticeCard"
            variant-class="dark"
            button-text="Conocer Más"
            @on-button-action="goToPlans">
            <template v-slot:text>¡Invierte de forma <span>automática</span>!</template>
          </notice-card>

          <notice-card
            v-if="showSeedProjectsCard"
            variant-class="dark"
            button-text="¡Descúbrelo!"
            @on-button-action="goToSeedProjects">
            <template v-slot:text>¿Qué son los <span>proyectos semilla</span>?</template>
          </notice-card>

          <notice-card
            v-if="showInvestmentCard"
            variant-class="blue"
            button-text="Abonar fondos"
            button-id="add_funds_btn"
            @on-button-action="goToFunds">
            <template v-slot:text>¡Abona <span>fondos</span> a tu cuenta!</template>
          </notice-card> -->

            <!-- <notice-card
            v-if="showResaleProjectsCard"
            variant-class="clear"
            button-text="Descúbrelos"
            @on-button-action="goToResaleLanding">
            <template v-slot:text>Sistemas recuperados en <span>venta</span>.</template>
          </notice-card> -->

            <!-- <notice-card
            v-if="showImpactaMasCard"
            variant-class="green"
            button-text="¡Descubre cómo!"
            @on-button-action="goToImpactaMas">
            <template v-slot:text>¿Ya <span>subiste</span> de nivel?</template>
          </notice-card>

          <notice-card
            v-if="showRedferidosCard"
            variant-class="green"
            button-text="¡Invita a tus amigos!"
            @on-button-action="goToRedferidos">
            <template v-slot:text>¿A cuántos has <span>redferido</span>?</template>
          </notice-card>

          <informative-card variant-class="clear" v-if="isMounted">
            <template slot="title"> {{ moneyFormat(globalImpacts.financed) }} en energía limpia.</template>
            <template slot="content">
              Gracias a tu participación y la de otros {{ format_price(globalImpacts.investors) }} inversionistas, al día {{ midDateFormat(globalImpacts.date) }}
              logramos financiar {{ globalImpacts.projects }} proyectos semilla en {{ states }} estados de la república.
            </template>
            <template slot="actions">
              <vs-button type="border" class="border-2" @click="goToInvestments">¡Sigamos contribuyendo!</vs-button>
            </template>
          </informative-card>
        </div> -->
            <!-- END COL 1 -->

            <!-- COL 2 -->
            <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <info-projects-card :projects="deliveredProjects" />

          <informative-card variant-class="clear" v-if="isMounted">
            <template slot="title">¡Evitamos {{ format_price(globalImpacts.emission_reduction) }} ton. de CO<sub>2</sub>!</template>
            <template slot="content">
              Juntos seguimos limpiando nuestro planeta al reducir las emisiones de dióxido de carbono. ¡No dejes de invertir con impacto!
            </template>
            <template slot="actions">
              <vs-button type="border" class="border-2" @click="goToImpactaMas">¡Impacta más!</vs-button>
            </template>
          </informative-card>
        </div> -->
            <!-- END COL 2 -->

            <!-- COL 3 -->
            <!-- <div class="vx-col xxl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 w-full">
          <investor-goals-card
            v-if="isMounted"
            :factors="factors"
            :investor-impacts="investorImpacts"
            :total-investor-invested="totalInvestorInvested" />
        </div> -->
            <!-- END COL 3 -->
<!-- TERMINAN COMPONENTES VERSION ANTERIOR DE HOME -->
         </div>

         
         <div class="vx-row mt-5">
            <div class="vx-col w-full">
               <div class="flex">
                  <feather-icon icon="InfoIcon" svgClasses="w-8 h-8 text-primary" class="mr-3" />
                  <span class="bold mt-1 italic">El valor de tu portafolio es la suma del saldo disponible para inversión, los
                     compromisos de inversión y el monto de inversión fija.</span>
                  </div>
            </div>
         </div>

         <template v-if="showFixedInvestmentChart">
            <div class="vx-row mt-base">
               <div class="vx-col w-full">
                  <div class="flex items-end px-3">
                     <h2>Inversión fija y rendimientos</h2>
                  </div>
               </div>
            </div>

            <vx-card class="mb-base mt-5">
               <div class="vx-row">
                  <div class="vx-col xl:w-3/4 lg:w-3/4 md:w-full sm:w-full w-full mt-5">
                     <div class="vx-row mb-2">
                        <div class="vx-col w-full">
                           <vue-apex-charts type="line" height="400" :options="chartOptions" :series="series"></vue-apex-charts>
                        </div>
                     </div>
                  </div>
                  <div class="vx-col xl:w-1/4 lg:w-1/4 md:w-full sm:w-full w-full mt-5">
                     <h3>¿Cómo calculamos tus rendimientos?</h3>
                     <div class="mt-5">
                        <p>
                           En esta gráfica te mostramos tu inversión fija y % de rendimiento mes con mes.
                           <br><br>El rendimiento se calcula de la siguiente manera.
                           <b>(Ganancias - Comisiones) / Inversión fija mes anterior x 12 (para anualizarlo)</b>. Las Ganancias son la suma de los intereses ordinarios y moratorios, 
                           así como cualquier bonificación adicional que hayas recibido.
                        </p>
                     </div>
                  </div>
               </div>
            </vx-card>
         </template>

      </div>
   </div>
</template>

<script>

import ComplianceBanner from "@components/compliance/ComplianceBanner";
import NoticeCard from "@components/investor/widgets/NoticeCard";
import InformativeCard from "@components/investor/widgets/InformativeCard";
import InfoProjectsCard from "@components/investor/widgets/InfoProjectsCard";
import InvestorGoalsCard from "@components/investor/widgets/InvestorGoalsCard";
import formatHelper from "@mixins/formatHelper";
import stpHelper from "@mixins/stpHelper";
import dateHelper from "@mixins/dateHelper";
import userIdentityHelper from "@/helpers/userIdentityHelper";
import investorInfoHelper from "@/helpers/investorInfoHelper";
import FundReady from "@components/compliance/FundReady";
import { mapState } from "vuex";
import VueApexCharts from 'vue-apexcharts';
import InvestorContractLevelBanner from "@components/investor/plans/InvestorContractLevelBanner";
import InvestmentPlansBanner from "@components/investor/plans/InvestmentPlansBanner";

export default {
   components: {
      NoticeCard,
      InformativeCard,
      InfoProjectsCard,
      InvestorGoalsCard,
      ComplianceBanner,
      FundReady,
      VueApexCharts,
      InvestorContractLevelBanner,
      InvestmentPlansBanner
   },
   mixins: [formatHelper, stpHelper, dateHelper, userIdentityHelper, investorInfoHelper],
   data() {
      return {
         isMounted: false,
         requirements: [],
         showPanel: null,
         showVerificationAccountCard: false,
         showAutomaticInvestmentNoticeCard: false,
         showResaleProjectsCard: false,
         showImpactaMasCard: false,
         showRedferidosCard: false,
         showInvestmentCard: false,
         showSeedProjectsCard: false,
         states: 29,
         showDemoCards: false,
         deliveredProjects: [],
         globalImpacts: {
            emission_reduction: 0,
            financed: 0,
            investors: 0,
            projects: 0,
            date: null,
         },
         factors: {
            ton_to_money_factor: 1,
            gain_to_investment_factor: 1,
         },
         totalInvestorInvested: 0,
         investorImpacts: {},
         identity: null,
         identityStatus: 0,
         funds: 0,
         hasAutomaticInvestmentInactive: false,
         stpClabe: null,
         user_person_type: null,
         availableToInvest: 0,
         investmentCommitments: 0,
         activeFixedInvestment: 0,
         totalPortfolio: 0,
         showFixedInvestmentChart: false,
         figs: {
            arrows: require('@assets/images/investor-figs/home-arrows-up.svg'),
            portfolio: require('@assets/images/investor-figs/home-portfolio.svg'),
            invest: require('@assets/images/investor-figs/home-invest.svg'),
            commitment: require('@assets/images/investor-figs/home-commitment.svg'),
            fixed: require('@assets/images/investor-figs/home-fixed-investment.svg'),
         },

         series: [{
            name: "",
            data: []
         }],
         chartOptions: {
            chart: {
               zoom: {
                  enabled: true
               },
               toolbar: {
                  tools: {
                  download: true,
                  zoom: true,
                  zoomin: true,
                  zoomout: true,
                  pan: true,
                  reset: true,
                  },
                  autoSelected: 'none'
               },
               locales:[{
                  "name": "en",
                  "options": {
                  "toolbar": {
                     "exportToPNG": "Descargar PNG",
                     "exportToCSV": "Descargar CSV",
                     "selectionZoom": "Zoom en área",
                     "zoomIn": "Acercar",
                     "zoomOut": "Alejar",
                     "pan": "Paneo",
                     "reset": "Reestablecer"
                  }
                  }
               }],
               defaultLocale: "en"
            },
            grid: {
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            colors: ['#28de18', '#545454'],
            dataLabels: {
               enabled: false
            },
            xaxis: {
            },
            yaxis: [
              {
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: '#28de18'
                },
                labels: {
                  formatter: (value) => { return this.explicitMoneyFormat(value, 0) }
                },
                title: {
                  text: "Inversión fija",
                  style: {
                    color: '#28de18',
                  }
                },
                tooltip: {
                  enabled: true
                }
              },
              {
                seriesName: 'Income',
                opposite: true,
                axisTicks: {
                  show: true,
                },
                axisBorder: {
                  show: true,
                  color: '#545454'
                },
                labels: {
                  formatter: (value) => { return this.decimalPercentFormat(value, 2) }
                },
                title: {
                  text: "Rendimiento",
                  style: {
                    color: '#545454',
                  }
                },
              },
            ],
            noData: {
               text: 'Cargando...'
            }
         },
         mainComplianceRequirements: 0
      }
   },
   computed: {
      ...mapState("auth", ["roleGeneralData"]),
      isMexicanPM() {
         return this.user_person_type === 0;
      },
      isMexicanPF() {
         return this.user_person_type === 1;
      },
      isSmallerScreen(){
         return this.$store.state.windowWidth <= 576;
      },
   },
   async beforeMount() {
      this.user_person_type = parseInt(this.UserPersonType);
   },
   async mounted() {
      this.isMounted = false;
      await this.showLoading(true)
      await this.onUserInfoUpdated();
      await this.getDataForHome();
      await this.getPlansInfo();
      await this.checkForStpInfo();
      await this.setCardsSettings();
      this.isMounted = true
      await this.showLoading(false)
   },
   methods: {
      async getDataForHome() {
         try {
            let response = await axios.get(`api/v2/investor/${this.InvestorId}/getDataForHomePage`);

            // DATOS PARA VERSION ANTERIOR DE HOME
            // this.deliveredProjects = response.data.deliveredProjects;
            // if (response.data.globalImpacts !== null) {
            //    this.globalImpacts = response.data.globalImpacts;
            // }
            // this.identity = response.data.identity;
            // this.factors = response.data.factors;
            // this.totalInvestorInvested = response.data.totalInvested;
            // this.investorImpacts = response.data.investorImpacts;
            // this.funds = response.data.funds;

            // DATOS PARA VERSION ACTUAL DE HOME
            this.identity = response.data.identity;
            this.availableToInvest = response.data.availableToInvest;
            this.investmentCommitments = response.data.investmentCommitments;
            this.activeFixedInvestment = response.data.activeFixedInvestment;
            this.totalPortfolio = response.data.totalPortfolio;
            let seriesPercentageData = [];
            let seriesData = [];
            let seriesLabels = [];
            response.data.chartData.forEach(f => {
               seriesData.push(f.fixed_investment);
               seriesLabels.push(f.balance_date);
               seriesPercentageData.push(f.total);
            });
            this.series = [
               {
                  name: "Inversión fija (MXN)",
                  data: seriesData
               },
               {
                  name: "Rendimiento",
                  data: seriesPercentageData
               },
            ];
            this.chartOptions.xaxis = {
               categories: seriesLabels
            };

            // SI LOS DATOS PARA LA GRAFICA DE INVERSION FIJA TIENE MAS DE UN MES SE MUESTRA
            if (response.data.chartData.length > 1) {
               this.showFixedInvestmentChart = true;
            }

            // SE VALIDAN DATOS PARA MOSTRAR O NO EL BANNER DE LA VERIFICACION DE IDENTIDAD
            if (this.identity) {
               this.identityStatus = this.identity.identity_status;
            }
         }
         catch (e) {
            this.warn(e);
         }
      },
      async getPlansInfo() {
         try {
            const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
            const investorPlan = res.data.plan;
            this.hasAutomaticInvestmentInactive = investorPlan.autoinvest_applicant_payments === 0;
         }
         catch (e) {
         }
      },
      async checkForStpInfo() {
         // solicitar informacion de stp solo si corresponde
         // panel completo y persona fisica mexicana
         if (this.isMexicanPF || this.isMexicanPM) {
            this.stpClabe = await this.getInvestorAccountStpClabe(this.InvestorId);
         }
      },
      async setCardsSettings() {
         // tarjeta: abonar fondos
         // Aparece cuando su saldo es igual a 0 y tiene clabe stp
         if (this.roleGeneralData.balance === 0 && this.stpClabe !== null) {
            this.showInvestmentCard = true;
            // this.$router.replace({name: 'abonarFondos'});
            return;
         }
         // tarjeta: proyectos semilla
         // Aparece cuando su estatus es menor a 3 o no tiene clabe stp
         if (this.InvestorStatus < 3 || this.stpClabe === null) {
            this.showSeedProjectsCard = true;
            return;
         }
         // tarjeta: proyectos semilla
         // Aparece cuando su saldo es mayor a 0 e investor status es menor a 3
         if (this.roleGeneralData.balance > 0 && this.InvestorStatus < 3) {
            this.showSeedProjectsCard = true;
            return;
         }
         if (this.InvestorStatus > 2) {
            // tarjeta: automatiza tus inversiones
            // Aparece cuando investor status es mayor a 2 y tiene deshabilitada su inversion automatica
            if (this.hasAutomaticInvestmentInactive) {
               this.showAutomaticInvestmentNoticeCard = true;
               return;
            }

            // si existen proyectos en reventa se hace un random para las cards
            if (this.ResaleProjects > 0) {
               var option = this.getRandomIntInclusive(1, 3);
               this.showImpactaMasCard = false;
               this.showRedferidosCard = false;
               this.showResaleProjectsCard = false;
               switch (option) {
                  case 1:
                     this.showImpactaMasCard = true;
                     break;
                  case 2:
                     this.showRedferidosCard = true;
                     break;
                  // case 3:
                  //   this.showResaleProjectsCard = true;              
                  //   break;
               }
            }
            // si no se sigue con la logica normal de dias pares y nones
            else {
               // tarjetas por dias
               let day = new Date().getDate();
               let isEven = (day % 2 === 0);
               // tarjeta: subiste de nivel
               //Aparece los días pares
               this.showImpactaMasCard = isEven;
               // tarjeta: redferidos
               //Aparece los días nones
               this.showRedferidosCard = !isEven;
            }
         }
      },
      async goToProfile() {
         await this.$router.push({ name: 'perfilInversionista' });
      },
      async goToAboutYou() {
         await this.$router.push({ name: 'perfilInversionistaAbout' });
      },
      async goToSeedProjects() {
         window.open("https://blog.redgirasol.com/como-funcionan-los-proyectos-semilla/", "_blank");
      },
      async goToPortfolio() {
         await this.$router.push({ name: 'miPortafolio' });
      },
      async goToImpactaMas() {
         await this.$router.push({ name: 'impactaMasInversionista' });
      },
      async goToRedferidos() {
         await this.$router.push({ name: 'redferidos' });
      },
      async goToInvestments() {
         await this.$router.push({ name: 'oportunidades' });
      },
      async goToFunds() {
         await this.$router.push({ name: 'abonarFondos' });
      },
      async goToPlans() {
         await this.$router.push({ name: 'planesInversion' });
      },
      async goToResaleLanding() {
         await this.$router.push({ name: 'recoveredProjects' });
      },
      async goToInvestorProjects() {
         await this.$router.push({ name: 'misProyectosInversionista' });
      },
      getRandomIntInclusive(min, max) {
         const randomBuffer = new Uint32Array(1);
         window.crypto.getRandomValues(randomBuffer);
         let randomNumber = randomBuffer[0] / (0xffffffff + 1);
         min = Math.ceil(min);
         max = Math.floor(max);
         return Math.floor(randomNumber * (max - min + 1)) + min;
      },
      loadedRequirements(count) {
         this.mainComplianceRequirements = count;
      },
   }
};
</script>
