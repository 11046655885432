<template>
  <div>
    <compliance-banner @loadedRequirements="loadedRequirements" :showBanner="false" />
    <div v-if="isMounted">
      <vx-card :card-background="'url(' + card_bg_img_1 + '), white'"
        style="background-position: right; background-size: contain; background-repeat: no-repeat;">
        <div class="flex mb-3">
          <feather-icon :icon="dataTitles.icon" svgClasses="w-5 h-5 text-success" class="mr-2" />
          <h3>{{ dataTitles.title }}</h3>
          <label
            v-if="investorPlan.investment_plan == 'compound_interest' || investorPlan.investment_plan == 'passive_income'"
            class="text-success bold mt-1 ml-3">Activo</label>
        </div>

        <label> {{ dataTitles.subtitle }} </label>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <div v-if="!investorPlan.investment_plan">
              <vs-button size="small" @click.stop="goToInvestmentPlansMainPage">Activar</vs-button>
            </div>
            <div v-else>
              <div class="vx-row" v-if="investorPlan.investment_plan == 'compound_interest'">
                <div class="vx-col w-full">
                  <vs-button v-if="!investorPlan.autoinvest_applicant_payments && !investorPlan.autoinvest_deposits"
                    @click.stop="goToAskMonthlyInvestPage" :disabled="isButtonDisabled">Activar interés
                    compuesto</vs-button>
                  <div class="flex" v-else>
                    <vs-button size="small" v-if="canActivateDirectBillingFirstTime" @click.stop="goToMonthlyInvestPage"
                      :disabled="isButtonDisabled">Activar abono
                      recurrente</vs-button>
                    <template v-else>
                      <vs-button size="small" v-if="investorPlan.suspendedDirectBillingBankAccount"
                        @click.stop="confirmSwitchInvestmentPlanOption('autoinvest_deposits', 1)"
                        :disabled="isButtonDisabled">Reactivar abono
                        recurrente</vs-button>
                      <vs-button size="small" v-else
                        @click.stop="confirmSwitchInvestmentPlanOption('autoinvest_deposits', 0)"
                        :disabled="isButtonDisabled">Pausar
                        abono
                        recurrente</vs-button>
                    </template>
                    <vs-button size="small" class="ml-6" color="dark"
                      @click.stop="confirmSwitchInvestmentPlanOption('cancel_plan', 1)"
                      :disabled="isButtonDisabled">Cancelar plan</vs-button>
                  </div>
                </div>
              </div>
              <div class="vx-row" v-if="investorPlan.investment_plan == 'passive_income'">
                <div class="vx-col w-full">
                  <vs-button v-if="!investorPlan.investment_plan" @click.stop="goToPassiveIncomeInvestPage"
                    :disabled="isButtonDisabled">Activar
                    ingreso
                    pasivo</vs-button>
                  <div class="flex" v-else>
                    <vs-button v-if="canActivateRecurrentWithdrawalFirstTime" @click.stop="goToRecurrentWithdrawalPage"
                      :disabled="isButtonDisabled">Activar retiro
                      recurrente</vs-button>
                    <vs-button v-else type="border" @click.stop="goToRecurrentWithdrawalPage"
                      :disabled="isButtonDisabled">Editar
                      datos</vs-button>
                    <vs-button class="ml-6" color="dark"
                      @click.stop="confirmSwitchInvestmentPlanOption('cancel_plan', 1)"
                      :disabled="isButtonDisabled">Cancelar el
                      plan</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <!-- Componente para obtener la geolocalización -->
      <rg-geolocator ref="rgGeolocator" />
    </div>
  </div>
</template>

<script>
import formatHelper from "@mixins/formatHelper";
import ComplianceBanner from "@components/compliance/ComplianceBanner";

export default {
  name: "InvestmentsPlansBanner",
  components: { ComplianceBanner },
  mixins: [formatHelper],
  data() {
    return {
      contractCancelDirectBillingType: 20,
      card_bg_img_1: require('@assets/images/figs/leaf.png'),
      isMounted: false,
      investorPlan: {
        id: null,
        card_id: null,
        card_amount: null,
        reinvestment_percentage: null,
        qualifications: null,
        monthly_day: null,
        available_money: null,
        retry: null,
        min_autoinvest_project_amount_mxn: null,
        max_autoinvest_project_amount_mxn: null,
        autoinvest_applicant_payments: null,
        autoinvest_deposits: null,
        suspendedDirectBillingBankAccount: null,
      },
      switchPlanOption: null,
      switchPlanValue: null,
      mainComplianceRequirements: null,
    }
  },
  async mounted() {
    this.showLoading(true);
    this.isMounted = false;
    await this.loadPrefs();
    this.isMounted = true;
    this.showLoading(false);
  },
  methods: {
    async loadPrefs() {
      await this.getPlansInfo();
    },
    async getPlansInfo() {
      try {
        const res = await axios.get(`api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`);
        this.investorPlan = res.data.plan;
        this.investorPlan.suspendedDirectBillingBankAccount = res.data.suspended_direct_billing_bank_account;
      }
      catch (e) {
      }
    },
    confirmSwitchInvestmentPlanOption(option, value) {
      let message = "";
      let title = "";
      this.switchPlanOption = option;
      this.switchPlanValue = value;
      let firstText = value == 1 ? "reactivar" : "pausar";
      let secondText = value == 1 ? "reactivado" : "pausado";
      let thirdText = firstText == "reactivar" ? "pausar" : "reactivar";
      switch (option) {
        case "autoinvest_deposits":
          title = "¿Estás seguro que deseas " + firstText + " el abono recurrente?";
          message = "El abono recurrente será " + secondText + ", lo puedes " + thirdText + " en cualquier momento.";
          break;
        case "autoinvest_applicant_payments":
          title = "¿Estás seguro que deseas " + firstText + " la inversión automática?";
          message = "El proceso de inversión automática será " + secondText + ", lo puedes " + thirdText + " en cualquier momento.";
          break;
        case "cancel_plan":
          // se pide la geolocalizacion para la operacion
          this.$refs.rgGeolocator.askForGeolocation();
          title = "¿Estás seguro que deseas cancelar el plan de inversión?";
          message = "Al confirmar tu plan sera cancelado, pero puedes crearlo nuevamente en cualquier momento.";
          break;
        default:
          break;
      }
      this.confirmationDialog(
        this.switchInvestmentPlanOption,
        title,
        message,
        "Confirmar",
        "Cancelar"
      );
    },
    async switchInvestmentPlanOption() {
      this.showLoading(true)
      try {
        let payload = {
          option: this.switchPlanOption,
          value: this.switchPlanValue,
          geolocation: null,
          contractType: null
        };

        if (this.switchPlanOption == "cancel_plan") {
          payload.geolocation = this.CurrentGeolocationData;
          payload.contractType = this.contractCancelDirectBillingType;
        }
        this.injectAccountMetadataToPayload(payload);
        await axios.post(`api/v2/investor/${this.InvestorId}/switchInvestmentPlanOption`, payload);
        this.showLoading(true);
        this.isMounted = false;
        await this.loadPrefs();
        //  this.factorEnabled = await this.getUser2FAStatus(this.UserId);
        this.isMounted = true;
        this.showLoading(false);
        if (this.switchPlanOption == "cancel_plan") {
          this.successNotifDialog('Operación exitosa',
            'Tu plan ha sido cancelado recuerda que puedes crear uno nuevo en cualquier momento.');
        } else {
          this.successNotifDialog('Operación exitosa',
            'Se han actualizado exitosamente tus preferencias sobre el plan de inversión.');
        }
      }
      catch (e) {
        const mssg = this.catchError(e);
        this.errorNotif('Error en la operación', mssg);
      }
      this.showLoading(false);
    },
    catchError(e) {
      if (e?.response?.data?.error) {
        return e.response.data.error
      }
      else {
        return "No ha sido posible actualizar la información";
      }
    },
    async goToInvestmentPlansMainPage() {
      await this.$router.replace({ name: 'planesInversion' });
    },
    async goToMonthlyInvestPage() {
      await this.$router.replace({ name: 'abonoRecurrente' });
    },
    async goToAskMonthlyInvestPage() {
      await this.$router.replace({ name: 'opcionalAbonoRecurrente' });
    },
    async goToPassiveIncomeInvestPage() {
      await this.$router.replace({ name: 'ingresoPasivo' });
    },
    async goToRecurrentWithdrawalPage() {
      await this.$router.replace({ name: 'retiroRecurrente' });
    },
    loadedRequirements(count) {
      this.mainComplianceRequirements = count;
    }
  },
  computed: {
    dataTitles() {
      let data = {
        icon: null,
        title: null,
        subtitle: null,
      };
      switch (this.investorPlan.investment_plan) {
        case 'compound_interest':
          data.icon = "SunriseIcon";
          data.title = "Interés compuesto";
          data.subtitle = "Reinvierte el 100% de los pagos que recibas para generar interés sobre interés.";
          break;
        case 'passive_income':
          data.icon = "LockIcon";
          data.title = "Ingreso pasivo";
          data.subtitle = "Mantén una inversión fija y retira el excedente para crear un ingreso pasivo.";
          break;
        default:
          data.icon = "DropletIcon";
          data.title = "Planes de inversión";
          data.subtitle = "Configura tu plan a la medida y maximiza el valor de tu cuenta con el mínimo esfuerzo.";
          break;
      }
      return data;
    },
    canActivateDirectBillingFirstTime() {
      return (this.investorPlan.monthly_day == 0 && !this.investorPlan.autoinvest_deposits);
    },
    canPauseDirectBilling() {
      return (this.investorPlan.monthly_day != 0 && this.investorPlan.autoinvest_deposits);
    },
    canActivateRecurrentWithdrawalFirstTime() {
      return (this.investorPlan.recurrent_withdrawal_day == 0 && !this.investorPlan.recurrent_withdrawal_bank_account_id);
    },
    isButtonDisabled() {
      return this.mainComplianceRequirements != 0;
    }
  }
}
</script>